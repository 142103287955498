.cta:hover {
  -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  background-image: linear-gradient(-180deg, #D3FDEE 0%, #C2FED6 53%, #B2FFE1 100%);
  border: 1px solid #92EC93;
  box-shadow: 0 10px 10px 0 #8EBE9B, 0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 1px #FFFFFF;
  color: rgba(50, 89, 49, 0.75);
  transform: translateY(-5px);
}

.cta {
  display: inline-block;
  position: relative;
  display: inline-block;

  /* padding: auto; */
  text-align: center;

  -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background-image: linear-gradient(-180deg, #FDF4D3 0%, #FEE9C2 53%, #FFDFB2 100%);
  height: 40px;
  /* line-height: 70px; */
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid #ECCF92;
  box-shadow: 0 2px 0 0 #CBB384, 0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 1px #fffaea;
  border-radius: 3px;
  padding: 0 45px;
  font-weight: normal;
  position: relative;
}

.dashboard-click:hover {
  -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  background-image: linear-gradient(-180deg, #D3FDEE 0%, #C2FED6 53%, #B2FFE1 100%);
  border: 1px solid #92EC93;
  box-shadow: 0 10px 10px 0 #8EBE9B, 0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 1px #FFFFFF;
  color: rgba(50, 89, 49, 0.75);
  transform: translateY(-5px);
}

.dashboard-click {


  -webkit-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 250ms cubic-bezier(0.23, 1, 0.32, 1);
  background-image: linear-gradient(-180deg, #FDF4D3 0%, #FEE9C2 53%, #FFDFB2 100%);
  /* line-height: 70px; */
  cursor: pointer;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid #ECCF92;
  box-shadow: 0 2px 0 0 #CBB384, 0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 1px #fffaea;
  border-radius: 3px;
  font-weight: normal;
}

.dashboard-click:active {
  transform: scale(1) translateZ(0) translateY(0px);
  box-shadow: 0 2px 0 0 #8EBE9B, 0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 1px #FFFFFF;
}

.conversation-action-item-typography {
  color: #262626;
  font-family: 'Public Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
